<template>
  <div style="background: #fff">
    <van-nav-bar right-text="Go岗位"
                 @click-right="onClickRight">
      <template #title>
        <span style="font-weight: 600; color: #0af">GOLANG ROADMAP·手机端</span>
      </template>
      <template #left>
        <van-image radius="18px"
                   height="40px"
                   width="40px"
                   src="https://image-1302243118.cos.ap-beijing.myqcloud.com/img/toux.jpg" />
      </template>
    </van-nav-bar>
    <div style="background: #fff"
         align="center">
      <van-image fit="scale-down"
                 src="https://image-1302243118.cos.ap-beijing.myqcloud.com/interview/books/Go_8001611039611515.gif">
        <template v-slot:loading>
          <van-loading type="spinner"
                       size="20"
                       color="#1989fa">加载中...</van-loading>
        </template>
      </van-image>
    </div>
    <van-notice-bar left-icon="volume-o"
                    text="平台采用邀请制，首次登录请填写邀请码，无邀请码者，可扫描下方公众号获取。"
                    background="rgb(0 170 255 / 22%)" />
    <van-form>
      <van-field v-model="phonenum"
                 type="tel"
                 clearable
                 left-icon="phone-o"
                 name="phonenum"
                 label="手机号"
                 placeholder="注册手机号"
                 :rules="[{ validator: validatePhone, message: '请填写正确手机号' }]" />

      <captcha id="Captcha"
               :scene="phonenum"
               type="TencentCaptcha"
               :parm="captchaOption"
               @callback="captchaNotice"
               url="https://api.golangroadmap.com/captcha">
        <van-field v-model="verifyCode"
                   center
                   clearable
                   left-icon="envelop-o"
                   label="验证码"
                   placeholder="请输入短信验证码"
                   :rules="[{ required: true, message: '请填写验证码' }]">
          <template #button>
            <van-button id="Captcha"
                        size="small"
                        type="info"
                        :disabled="time > 0">获取验证码{{ time > 0 ? `(${time}s)` : "" }}</van-button>
          </template>
        </van-field>
      </captcha>

      <van-field v-model="invitationCode"
                 clearable
                 left-icon="eye-o"
                 right-icon="question-o"
                 @click-right-icon="passwordToast"
                 name="邀请码"
                 label="邀请码"
                 placeholder="首次登录必填" />
      <div style="margin: 16px">
        <van-button round
                    block
                    type="info"
                    @click="handleLogin"
                    native-type="submit">登录</van-button>
      </div>
    </van-form>
    <van-divider :style="{ color: '#0af', borderColor: '#1989fa', padding: '0 16px' }">
      关注公众号·访问不迷路
    </van-divider>
    <div style="background: #fff"
         align="center">
      <van-image fit="scale-down"
                 width="240px"
                 src="https://image-1302243118.cos.ap-beijing.myqcloud.com/public/img/wx.png">
        <template v-slot:loading>
          <van-loading type="spinner"
                       size="20"
                       color="#1989fa">加载中...</van-loading>
        </template>
      </van-image>
    </div>
    <van-divider :style="{ color: '#0af', borderColor: '#1989fa', padding: '0 16px' }">
      关注Gopher学习求职之路
    </van-divider>
  </div>
</template>

<script>
import {
  Divider,
  Dialog,
  Form,
  Field,
  Icon,
  Button,
  NoticeBar,
  Image as VanImage,
  Col,
  Row,
  NavBar,
  Loading,
} from "vant";

import captcha from "vue-social-captcha";

const axios = require("axios");

export default {
  components: {
    captcha,
    [Divider.name]: Divider,
    [Dialog.Component.name]: Dialog.Component,
    [Loading.name]: Loading,
    [axios.name]: axios,
    [Form.name]: Form,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar,
    [VanImage.name]: VanImage,
    [Col.name]: Col,
    [Row.name]: Row,
    [NavBar.name]: NavBar,
  },
  data () {
    return {

      phonenum: "",
      verifyCode: "", //验证码
      invitationCode: "", //邀请码
      time: 0,
      captchaOption: {
        // 各平台的参数，具体请参阅个平台文档
        //以下为腾讯验证码的参数
        appid: "2092612230",
      },

    };
  },
  created () {
    this.invitationCode = this.$route.query.code == null ? "" : this.$route.query.code
    if (this.invitationCode == "") {
      this.invitationCode = window.localStorage.getItem('code') === undefined ? "" : window.localStorage.getItem('code')
    }
  },
  methods: {
    // 图形校验回调回调监听
    // status: 1成功,2验证中,0失败
    // res: 三方返回的原始数据
    captchaNotice (status, res) {
      //前端校验手机号
      // 校验通过
      if (status == 1 && this.validatePhone()) {
        if (this.time > 0) {
          return;
        }
        this.time = 60;
        let timer = setInterval(() => {
          if (this.time == 0) {
            clearInterval(timer);
          } else {
            this.time--
          }
        }, 1000)
      } else if (status == 0 && res.code == 211) {
        Dialog({
          title: "提示",
          message: res.msg,
          theme: "round-button",
          confirmButtonColor: "#0af",
        });
      }
    },

    passwordToast () {
      Dialog({
        title: "提示",
        message: "平台注册采用邀请制，首次登录需要填写邀请码！",
        theme: "round-button",
        confirmButtonColor: "#0af",
      });
    },
    handleLogin () {
      if (this.validatePhone()) {
        const params = new URLSearchParams()
        params.append('phonenum', this.phonenum)
        params.append('authCode', this.verifyCode)
        params.append('invitationCode', this.invitationCode)

        axios.post('https://api.golangroadmap.com/login', params)
          .then((response) => {
            if (response.status == '200' && response.data.code == '100') {

              window.localStorage.setItem('auth-token', response.data.data.token)
              window.localStorage.setItem('invitationCode', response.data.data.userVo.invitationCode)
              window.sessionStorage.setItem('userVo', window.JSON.stringify(response.data.data.userVo))
              //vip
              window.sessionStorage.setItem('vToken', response.data.data.vToken)

              this.$router.push('home');

            } else {
              Dialog({
                message: response.data.msg,
                theme: "round-button",
                confirmButtonColor: "#0af",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    validatePhone () {
      //判断输入的手机号是否合法
      if (!this.phonenum) {
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(this.phonenum)) {
        return false;
      } else {
        return true;
      }
    },

    //跳转主站
    onClickRight () {
      this.$router.push("post");
    },
  },
};
</script>

<style lang="less">
</style>
